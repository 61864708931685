const routes = [
  {
    path: ["/"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/faq"],
    exact: true,
    component: "FAQ",
  },
  {
    path: ["/mint"],
    exact: true,
    component: "Items",
  },
  {
    path: ["/pass"],
    exact: true,
    component: "Pass",
  },
  // {
  //   path: ["/items"],
  //   exact: true,
  //   component: "Items",
  // },
  {
    path: ["/staking"],
    exact: true,
    component: "Staking",
  },
  {
    path: ["/whitelist"],
    exact: true,
    component: "Whitelist",
  },
  {
    path: ["/card"],
    exact: true,
    component: "Card",
  },
  {
    path: ["/discord"],
    exact: false,
    component: "Discord",
  },
];

export default routes;
