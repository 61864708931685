import { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import "../styles/theme.css";
import "../styles/reset.css";
import routes from "./config";
import Header from "../components/Header";
import Footer from "../components/Footer";
import withTracker from "../helpers/withTracker";
 
const Router = () => {
  return (
    <Suspense fallback={null}>
      <Header />
      <Switch>
        {routes.map((routeItem) => {
          return (
            <Route
              key={routeItem.component}
              path={routeItem.path}
              exact={routeItem.exact}
              component={withTracker(
                lazy(() => import(`../pages/${routeItem.component}`))
              )}
            />
          );
        })}
        <Redirect to="/" />
      </Switch>

      <Footer />
    </Suspense>
  );
};

export default Router;
